<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  showExpand
  componentInfo="SailorRecordBookLineInfo"
  :iconExpandedOpen="iconExpandedOpen"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SErviceRecordBookLinesTable',
  data () {
    return {
      headers: [
        { value: 'number_page_book', text: this.$t('numberPage') },
        { value: 'date_start', text: this.$t('hireDate') },
        { value: 'date_end', text: this.$t('fireDate') },
        { value: 'status_document', text: this.$t('status') },
        { text: '', value: 'data-table-expand', align: 'end', sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({
            name: 'experience-records-line-info',
            params: {
              ...this.$route.params,
              documentID: this.$route.params.documentID,
              lineID: item.id },
            query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      iconExpandedOpen: {
        name: 'mdi-book-open-page-variant-outline',
        color: 'blue',
        tooltip: 'tooltip.showRecordLine'
      }
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.serviceRecordBookLines,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getRecordBookLinesEntry']),
    async getData (params) {
      this.getRecordBookLinesEntry({ ...this.$route.params, params })
    }

  }
}
</script>
